import '../css/app.css';
import './bootstrap';
import {createApp, h} from 'vue';
import VueTyped from 'vue3-typed-js';
import {VueReCaptcha} from 'vue-recaptcha-v3';
import VueSocialSharing from 'vue-social-sharing';
import {ZiggyVue} from 'ziggy-js';
import {createInertiaApp} from '@inertiajs/vue3';
import filters from '@/filters.js';
import permissions from '@/mixins/permissions.js';
import translatable from '@/mixins/translatable.js';
import {log} from '@/utils.js';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {i18nVue} from 'laravel-vue-i18n';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => (title !== appName ? (title ? `${title} | ${appName}` : appName) : title),
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        // Mail template icons
        Object.values(import.meta.glob('../images/mail/**/*.{png,svg,jpg}', {eager: true, query: '?url'}));

        const captchaKey = props.initialPage.props.recaptcha_site_key;
        const application = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue)
            .use(i18nVue, {
                resolve: async (lang) => {
                    const languages = import.meta.glob('../../lang/*.json', {eager: true});
                    return await languages[`../../lang/${lang}.json`];
                },
            })
            .use(VueReCaptcha, {siteKey: captchaKey, loaderOptions: {autoHideBadge: true}})
            .use(VueSocialSharing)
            .use(VueTyped)
            .mixin(translatable)
            .mixin(permissions)
            .provide('log', log); // Composable for Vue 3 components

        application.config.globalProperties.log = log; // Global property for Vue 2 components
        application.config.globalProperties.$filters = filters;
        // @see https://github.com/inertiajs/inertia/discussions/695
        delete el.dataset.page;

        application.mount(el);
    },
    progress: {
        // The delay after which the progress bar will appear, in milliseconds...
        delay: 250,
        // The color of the progress bar...
        color: '#29d',
        // Whether to include the default NProgress styles...
        includeCSS: true,
        // Whether the NProgress spinner will be shown...
        showSpinner: true,
    },
});
